/** @format */

// import UIkit from "uikit";
// // Add your JS customizations here
// (function (m, t) {
//   "use strict";
//   function v(e) {
//     return e && typeof e == "object" && "default" in e ? e : { default: e };
//   }
//   var s = v(m);
//   function p(e, n) {
//     if (!!e) {
//       if (typeof e == "string") return f(e, n);
//       var r = Object.prototype.toString.call(e).slice(8, -1);
//       if (
//         (r === "Object" && e.constructor && (r = e.constructor.name),
//         r === "Map" || r === "Set")
//       )
//         return Array.from(e);
//       if (
//         r === "Arguments" ||
//         /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(r)
//       )
//         return f(e, n);
//     }
//   }
//   function f(e, n) {
//     (n == null || n > e.length) && (n = e.length);
//     for (var r = 0, a = new Array(n); r < n; r++) a[r] = e[r];
//     return a;
//   }
//   function b(e, n) {
//     var r =
//       (typeof Symbol != "undefined" && e[Symbol.iterator]) || e["@@iterator"];
//     if (r) return (r = r.call(e)).next.bind(r);
//     if (
//       Array.isArray(e) ||
//       (r = p(e)) ||
//       (n && e && typeof e.length == "number")
//     ) {
//       r && (e = r);
//       var a = 0;
//       return function () {
//         return a >= e.length ? { done: !0 } : { done: !1, value: e[a++] };
//       };
//     }
//     throw new TypeError(`Invalid attempt to iterate non-iterable instance.
// In order to be iterable, non-array objects must have a [Symbol.iterator]() method.`);
//   }
//   var c = {
//       connected: function () {
//         var n = this;
//         (this.section = d()),
//           this.section ||
//             this.registerObserver(
//               t.observeMutation(
//                 document.body,
//                 function (r, a) {
//                   (n.section = d()), n.section && (a.disconnect(), n.$emit());
//                 },
//                 { childList: !0, subtree: !0 }
//               )
//             );
//       },
//     },
//     $ = {
//       mixins: [c],
//       connected: function () {
//         var n = this;
//         this.registerObserver(
//           t.observeResize(this.$el, function () {
//             return n.$emit("resize");
//           })
//         );
//       },
//       update: [
//         {
//           read: function () {
//             return !o(this.section) || !this.$el.offsetHeight
//               ? !1
//               : { height: this.$el.offsetHeight };
//           },
//           write: function (n) {
//             var r = n.height;
//             console.log(r);
//             if (!t.hasClass(this.$el, "tm-header-overlay")) {
//               var a = o(this.section);
//               t.addClass(this.$el, "tm-header-overlay"),
//                 t.addClass(
//                   t.$$(
//                     ".tm-headerbar-top, .tm-headerbar-bottom, .js-toolbar-transparent"
//                   ),
//                   "uk-" + a
//                 ),
//                 t.removeClass(
//                   t.$$(".tm-headerbar-top, .tm-headerbar-bottom"),
//                   "tm-headerbar-default"
//                 ),
//                 t.removeClass(
//                   t.$(".js-toolbar-transparent.tm-toolbar-default"),
//                   "tm-toolbar-default"
//                 ),
//                 t.$("[uk-sticky]", this.$el) ||
//                   t.addClass(
//                     t.$(".uk-navbar-container", this.$el),
//                     "uk-navbar-transparent uk-" + a
//                   );
//             }
//             t.css(t.$(".tm-header-placeholder", this.section), { height: r });
//           },
//           events: ["resize"],
//         },
//       ],
//     },
//     y = {
//       mixins: [c],
//       update: {
//         read: function () {
//           var n = o(this.section);
//           if (!(!n || !t.closest(this.$el, "[uk-header]")))
//             return (
//               (this.animation = "uk-animation-slide-top"),
//               (this.clsInactive = "uk-navbar-transparent uk-" + n),
//               this.active || t.addClass(this.selTarget, this.clsInactive),
//               {
//                 start:
//                   this.section.offsetHeight <= t.toPx("100vh")
//                     ? t.offset(this.section).bottom
//                     : t.offset(this.section).top + 300,
//               }
//             );
//         },
//         events: ["resize"],
//       },
//     };
//   function d() {
//     return t.$(
//       '.tm-header ~ [class*="uk-section"], .tm-header ~ :not(.tm-page) > [class*="uk-section"]'
//     );
//   }
//   function o(e) {
//     return t.attr(e, "tm-header-transparent");
//   }
//   if (
//     (s.default.component("Header", $),
//     s.default.mixin(y, "sticky"),
//     s.default.mixin(
//       {
//         events: {
//           beforescroll: function () {
//             if (!this.$props.offset)
//               for (
//                 var n = b(t.$$("[uk-sticky] [uk-navbar]")), r;
//                 !(r = n()).done;

//               ) {
//                 var a = r.value;
//                 t.isVisible(a) && (this.offset = a.offsetHeight);
//               }
//           },
//         },
//       },
//       "scroll"
//     ),
//     t.isRtl)
//   ) {
//     var h = {
//       created: function () {
//         this.$props.pos = t.swap(this.$props.pos, "left", "right");
//       },
//     };
//     s.default.mixin(h, "drop"), s.default.mixin(h, "tooltip");
//   }
//   t.ready(function () {
//     var e = window,
//       n = e.$load,
//       r = n === void 0 ? [] : n,
//       a = e.$theme,
//       g = a === void 0 ? {} : a;
//     function u(i, l) {
//       i.length &&
//         i.shift()(l, function () {
//           return u(i, l);
//         });
//     }
//     u(r, g);
//   });
// })(UIkit, UIkit.util);

// console.log("test");

jQuery(document).ready(function ($) {
	let currentPage = 2;
	let canLoadMore = true;

	function getFilterData() {
		let filterData = {};

		// Get taxonomy terms filter values
		const brands = document.getElementsByName("b[]");
		filterData.brands = [];
		brands.forEach((checkbox) => {
			if (checkbox.checked) {
				filterData.brands.push(checkbox.value);
			}
		});

		// Get taxonomy terms filter values
		const categories = document.getElementsByName("c[]");
		filterData.taxonomy_terms = [];
		categories.forEach((checkbox) => {
			if (checkbox.checked) {
				filterData.taxonomy_terms.push(checkbox.value);
			}
		});

		// Get ACF field filter values
		const colors = document.getElementsByName("cl[]");
		filterData.cl = [];
		colors.forEach((checkbox) => {
			if (checkbox.checked) {
				filterData.cl.push(checkbox.value);
			}
		});

		return filterData;
	}

	function loadMorePosts() {
		let totalPages = parseInt($("#post-container").data("total-pages"), 10);

		if (canLoadMore) {
			canLoadMore = false;
			$("#loader").show();

			const filterData = getFilterData();

			$.ajax({
				url: theme_ajax.ajax_url,
				data: {
					action: "load_more_products",
					page: currentPage,
					filters: filterData,
				},
				type: "POST",
				success: function (data) {
          console.log({data});
					if (data.trim() !== "0") {
						$("#post-container").append(data);
						if (currentPage <= totalPages) {
							canLoadMore = true;
						} else {
							canLoadMore = false;
						}
					} else {
						// Optionally handle the case when there are no more posts to load
						// For example, you might want to hide the loader or display a message
						console.log("No more posts to load");
						canLoadMore = false;
					}
				},
				complete: function () {
					$("#loader").hide();
				},
			});

			currentPage++;
		}
	}

	$("#filter-form").on("submit", function (event) {
		event.preventDefault();
		currentPage = 1;
		$("#post-container").empty();
		loadMorePosts();
	});

	$(window).scroll(function () {
		if (
			$(window).scrollTop() + $(window).height() >=
				$(document).height() - 2000 &&
			canLoadMore
		) {
			loadMorePosts();
		}
	});
});
